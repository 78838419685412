import axios from "axios";
import BasePlayersModel from "../models/basePlayersModel";
import PlayersActivityModel from "../models/playersActivityModel";

const ZU_BASE_URL = process.env.REACT_APP_ZU_BASE_URL as string

function getZu(endpoint: string) {
  return axios.get(`${ZU_BASE_URL}${endpoint}`, {
    headers: {
      "X-ZUnivers-RuleSetType": "NORMAL"
    }
  })
    .then((resp) => resp.data)
    .catch((err) => console.error(err))
}

export function getUser(username: string): Promise<BasePlayersModel> {
  const endpoint = `/user/${encodeURIComponent(username)}`
  return getZu(endpoint)
}

export function getUserActivity(username: string): Promise<PlayersActivityModel> {
  const endpoint = `/loot/${encodeURIComponent(username)}`
  return getZu(endpoint)
}