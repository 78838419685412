import { useEffect, useState } from 'react'
import { getUser, getUserActivity } from '../typescript/request'
import { PlayersModel } from '../models/playersModel'

const PowiList: React.FC = () => {
  const [powiMembers, setPowiMembers] = useState<PlayersModel[]>([])

  useEffect(() => {
    async function getPowiMembers() {
      const powiUsernames = [
        'powaza',
        'hitsumo',
        'leeiplo',
        'weyrd',
        'chat_random',
        'wytsumo',
        'sisyphe_',
        'sunlight._.'
      ]
      let powis: PlayersModel[] = []

      Promise.all(powiUsernames.map(async (username) => {
        const user = await getUser(username)
        const activity = await getUserActivity(username)
        return powis.push({...user, ...activity})
      })).then(() => setPowiMembers(powis))
      console.log(powis)
    }

    getPowiMembers()
  }, [])

  const powiList = powiMembers.map(member => {
    let bonus = '❌'
    let pro = false

    const lastWeeklyLoot = member.lootInfos.slice(-7).reverse()
    lastWeeklyLoot.forEach((lootInfo) => {
      if (lootInfo.count >= 2000) {
        bonus = '✅'
        return
      }
    })

    member.lootInfos.forEach((lootInfo) => {
      if (lootInfo.count === 0) {
        pro = true
        return
      }
    })

    return (
      <div className='member' key={member.user.id}>
        <img src={member.user.discordAvatar} alt={member.user.discordUserName} className='member-img'/>
        <p className='member-name'>
          <a href={`https://zunivers.zerator.com/joueur/${encodeURIComponent(member.user.discordUserName)}`}>
            {member.user.discordUserName.split('#')[0]}
          </a>
          <sup className='pro' pro-tooltip={pro ? 'Professional ZU inattentive' : ''}>{pro ? ' Pro' : ''}</sup>
        </p>
        <p>Journa: {member.lootInfos[member.lootInfos.length - 1].count > 0 ? '✅' : '❌'}</p>
        <p>Bonus: {bonus}</p>
      </div>
    )
  })

  return(
    <div className='powi-list'>
      {powiList}
    </div>
  )
}

export default PowiList