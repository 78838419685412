import './App.css'
import NavBar from './components/navbar'
import PowiList from './components/powiList'

function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <PowiList />
      </main>
    </div>
  )
}

export default App
