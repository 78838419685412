const NavBar: React.FC = () => {
  return (
    <nav>
      <img src="/navbar.png" alt="" className="nav-bg" />
      <div className="div-nav-logo">
        <p className="nav-txt">ZU</p>
        <img src="/logo-mini.png" alt="" className="nav-logo"/>
        <p className="nav-txt">CHECKER</p>
      </div>
    </nav>
  )
}

export default NavBar